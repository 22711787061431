<template>
    <v-btn color="primary" dark @click="contratar"> Contratar

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :right="true"
      :timeout="3000"
      :color="snack_color"
    >
      {{ snack_text }}
    </v-snackbar>
         </v-btn>

</template>

<script>
export default {
    data: () => ({
        url: process.env.VUE_APP_API_URL,
        snackbar: false,
        snack_color: '',
        snack_text: '',
        espera: 6000
    }),
    methods: {
        go (msg, color) {
            this.snack_text = msg
            this.snack_color = color
            this.snackbar = true
        },
        contratar () {
            var order_infoJ = this.$store.getters.getjsonPedido
            const goInsert = async () => {
                const config = {
                    timeout: this.espera,
                    headers: {
                        Accept: 'application/json'
                    }
                }
                try {
                    return await this.$axios.post(
                        this.url + 'orders/',
                        order_infoJ,
                        config
                    )
                } catch (error) {
                    if (error.response.status === 422) {
                        this.go(error.response.data.error, 'error')
                    }
                }
            }

            const processgoInsertResult = async () => {
                const insert_result = await goInsert()
                if (insert_result) {
                    if (insert_result.data.success === true) {
                        this.go('Servicios solicitados', 'success')
                        this.$store.dispatch('setpedidoRealizado', true)
                    }
                }
            }
            processgoInsertResult()
        }
    }
}
</script>
